import React from 'react';
import { connect } from 'react-redux';
import DNCButton from '../components/DNCButton';
import { Descriptions, Button, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import i18next from 'i18next';


class ButtonGoogleSearch extends React.Component {
  render() {
    let displayButton = true;
    if (!this.props.content || this.props.content === "None") {
      displayButton = false
    }
    let url_encode_content = encodeURI(this.props.content);
    let google_search_url = "https://www.google.com/search?q=" + url_encode_content;
    return (
      <span>
        {
          displayButton
            ?
            < Tooltip title={i18next.t("Search on Google")} >
              <Button type="link" icon={<SearchOutlined />} style={{ "color": "#d7d7db" }} target="_blank" href={google_search_url} />
            </Tooltip >
            :
            <span></span>
        }
      </span>
    );
  }
}

class ContactDetails extends React.Component {
  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  render() {
    // Clean up the contact_fields
    let contact_fields = { ...this.props.contact_data.contact_fields }
    console.log(">> ContactDetails - subscriber_id: ", contact_fields['subscriber_id']);

    delete contact_fields['campaign_id'];
    delete contact_fields['contact_id'];
    delete contact_fields['subscriber_id'];
    delete contact_fields['internal_notes'];
    delete contact_fields['disposition'];
    delete contact_fields['tz_code'];
    delete contact_fields['gmt_offset'];
    delete contact_fields['count_attempt'];
    delete contact_fields['created_date'];
    let full_name = contact_fields['full_name'];
    let phone_number = contact_fields['phone_number'];
    let last_attempt = new Date(contact_fields['last_attempt']);
    last_attempt = moment(last_attempt).format('lll');
    delete contact_fields['full_name'];
    delete contact_fields['phone_number'];
    delete contact_fields['last_attempt'];

    console.log(contact_fields);
    const keys = Object.keys(contact_fields);
    let counter = 0;
    return (
      <div>
        <Descriptions
          title={
            <div>
              {i18next.t("Contact Info")}
              &nbsp; &nbsp;
              <DNCButton subscriber_id={contact_fields['subscriber_id']} />
            </div>
          }
          bordered layout="vertical"
          column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
          <Descriptions.Item label={i18next.t("Full name")}>
            {full_name}
            <ButtonGoogleSearch content={full_name} />
          </Descriptions.Item>
          <Descriptions.Item label={i18next.t("Contact Number")}>
            {phone_number}
            <ButtonGoogleSearch content={phone_number} />
          </Descriptions.Item>
          <Descriptions.Item label={i18next.t("Last Called")}>
            {last_attempt}
          </Descriptions.Item>
          {
            keys.map(item => {
              let label = this.Capitalize(item);
              counter = counter + 1;
              return (
                <Descriptions.Item label={label} key={counter}>
                  {contact_fields[item]}
                  <ButtonGoogleSearch content={contact_fields[item]} />
                </Descriptions.Item>
              );
            })
          }
        </Descriptions>
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    contact_data: state.contact.contact_data
  }
}

export default connect(mapStateToProps)(ContactDetails);
